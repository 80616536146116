export enum DisplaySettingEnumList {
  //Личные данные
  fio = 1,
  student_number = 2,

  // Информация об обучении
  // Факультет
  faculty,
  // Курс
  course,
  // Группа
  group,
  // Направление подготовки
  education_standard,
  // Форма обучения
  study_form,
  // Уровень подготовки
  training_level,
  // Кафедра
  department,
  // Учебный план
  education_plan,
  // Профиль (программа)
  education_program,
  // Академический статус
  academic_state,

  // Сведения о финансировании
  budget,
  budget_category,
  budget_sub_category,
  paid_training_agreement,

  // Контакты
  phone,
  city_phone,
  email,
  socialnetwork,

  // Прочее
  languages,
  elder,
  score_number,
  pedagogical_head,
  external_id,
  scientific_adviser_tutor,
  diploma_average,
  average_score_semester,
  admission_year,
  graduate_year
}

export const DisplaySettingEnum = DisplaySettingEnumList
