import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {NotificationsService} from "../../../services/Notifications/notifications.service";
import {StudentCardDisplaySettingService} from "../../../services/LKStudent/student-card-display-setting.service";
import {AllStudentCardDisplaySetting} from "../../../models/home/studentcarddisplaysetting.model";
import {DictDisplaySettingCategory} from "../../../models/home/dictdisplaysettingcategory.model";
import {DisplaySettingCategoryService} from "../../../services/LKStudent/display-setting-category.service";
import {DisplaySystemSettingCategoryService} from "../../../services/LKStudent/display-system-setting-category.service";
import {DisplaySystemSettingService} from "../../../services/LKStudent/display-system-setting.service";
import {AllDisplaySystemSetting} from "../../../models/home/displaysystemsetting.model";
import {DictDisplaySystemSettingCategory} from "../../../models/home/dictdisplaysystemsettingcategory";
import {DisplaySystemSettingCaregoryEnum} from "../../../models/home/enums/display-system-setting-category.enum";
import {DisplaySystemSettingEnum} from "../../../models/home/enums/display-system-setting.enum";
import {SystemSettingNotificationService} from "../../../services/LKStudent/system-setting-notification.service";
import {SystemSettingNotification} from "../../../models/home/systemsettingnotification.model";
import {EmailNotification} from "../../../models/home/emailnotification.model";
import {EmailNotificationService} from "../../../services/LKStudent/dictionaries/email-notification.service";

@Component({
  selector: 'app-studentcardsettings',
  templateUrl: './studentcardsettings.component.html',
  styleUrls: ['./studentcardsettings.component.scss']
})
export class StudentcardsettingsComponent implements OnInit {

  constructor(private router: Router,
              private displaySettingService: StudentCardDisplaySettingService,
              private displaySettingCategoryService: DisplaySettingCategoryService,
              private notificationService: NotificationsService,
              private displaySystemSettingService: DisplaySystemSettingService,
              private dictDisplaySystemSettingCategory: DisplaySystemSettingCategoryService,
              private systemSettingNotificationService: SystemSettingNotificationService,
              private emailNotificationService: EmailNotificationService)
  { }

  ngOnInit(): void {
    this.getDisplaySettings();
    this.getDisplaySettingCategory();
    this.getDisplaySystemSetting();
    this.getDisplaySystemSettingCategory();
    this.getSystemsSettingContactNotification();
    this.getEmailNotification();
  }

  public displaySettings: AllStudentCardDisplaySetting[] = [];
  public displayCategory: DictDisplaySettingCategory[] = [];
  public displaySystemSetting: AllDisplaySystemSetting[] = [];
  public displaySystemSettingCategory: DictDisplaySystemSettingCategory[] = [];
  public systemCategoryEnum = DisplaySystemSettingCaregoryEnum;
  public systemSetting = DisplaySystemSettingEnum;
  public systemSettingContactNotification: SystemSettingNotification = new SystemSettingNotification();
  public emailNotification: EmailNotification = new EmailNotification();
  public selected = 0;
  public tabItems = [
    { title: "Поля для отображения", },
    { title: "Настройки системы", },
  ];

  public getDisplaySettings() {
    this.displaySettingService.getAllStudentCardDisplaySettings()
      .subscribe(
        response => {
          this.displaySettings = response;
        }
      );
  }

  public getDisplaySettingCategory() {
    this.displaySettingCategoryService.getDisplaySettingCategories()
      .subscribe(
        response => {
          this.displayCategory = response;
        }
      );
  }

  public applyHandlerDisplaySetting(){
    this.displaySettingService.editStudentCardDisplaySetting(this.displaySettings)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public applyHandlerSystemSetting(){
    this.displaySystemSettingService.EditSystemSetting(this.displaySystemSetting)
      .subscribe({
        error: (error) => {
          this.notificationService.showError(error.error);
        }});

    this.systemSettingNotificationService.EditSystemsSettingContactNotification(this.systemSettingContactNotification)
      .subscribe({
        error: (error) => {
          this.notificationService.showError(error.error);
        }});

    this.emailNotificationService.EditEmailNotification(this.emailNotification)
      .subscribe({
        next:() => {
          this.notificationService.showSuccess("Сохранено");
        },
        error: (error) => {
          this.notificationService.showError(error.error);
        }});
  }

  public closeHandler(){
    this.router.navigateByUrl(`/home`).then();
  };

  public getDisplaySystemSetting() {
    this.displaySystemSettingService.GetAllSystemDisplaySettings()
      .subscribe(
        response => {
          this.displaySystemSetting = response;
        }
      );
  }

  public getDisplaySystemSettingCategory() {
    this.dictDisplaySystemSettingCategory.getDisplaySettingCategories()
      .subscribe(
        response => {
          this.displaySystemSettingCategory = response;
        }
      )
  }

  public getSystemsSettingContactNotification() {
    this.systemSettingNotificationService.GetSystemsSettingContactNotification()
      .subscribe(
        response => {
          this.systemSettingContactNotification = response;
        }
      )
  }

  public getEmailNotification() {
    this.emailNotificationService.GetEmailNotification()
      .subscribe(
        response => {
          this.emailNotification = response;
        }
      )
  }

  onValueChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (value === 'canNotEdit') {
      this.displaySystemSetting.filter(x => x.category === this.systemCategoryEnum.contacts).forEach(x => x.isDisplay = false);
    } else if (value === 'canEdit') {
      this.displaySystemSetting.filter(x => x.category === this.systemCategoryEnum.contacts).forEach(x => x.isDisplay = true);
    }
  }

  isCantEditSelected(): boolean {
    return this.displaySystemSetting.filter(x => x.type !== this.systemSetting.portfolio).every(x => x.isDisplay === false);
  }
}
