import {BehaviorSubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GetDataService {
}

// Для вызова события на получение данных в Grid
export const getData$ = new BehaviorSubject<boolean>(false);
