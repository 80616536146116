import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {saveAs} from '@progress/kendo-file-saver';
import {FileRestrictions} from '@progress/kendo-angular-upload';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {environment} from '../../../../environments/environment';
import {AchievementList} from '../../../models/portfolio/achievement.model';
import {DictDirectionActivity} from '../../../models/portfolio/dict.model';
import {EditEvent, RemoveEvent} from '@progress/kendo-angular-grid';
import {Guid} from 'guid-typescript';
import {PortfolioService} from "../../../services/LKPortfolio/portfolio.service";
import {DictService} from "../../../services/LKPortfolio/dict.service";
import {NotificationsService} from "../../../services/Notifications/notifications.service";
import {getErrorMessage} from "../../../../helpers/errorHandle-helper";
import {DialogHelper} from "../../../../helpers/dialogHelper";
import {LKStudentService} from "../../../services/LKStudent/lkstudent.service";
import {LKStudPerson} from "../../../models/profile/lkStudPerson.model";
import {LKStudent} from "../../../models/profile/lkstudent.model";

@Component({
  selector: 'app-simple-achievements',
  templateUrl: './simple-achievements.component.html',
  styleUrls: ['./simple-achievements.component.scss'],
})
export class SimpleAchievementsComponent implements OnInit {

  //#region VAR

  public loading = false;
  public editMode = false;
  public achievementStatusReturned = false;

  //#region File

  public restrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: environment.portfolio.maxFileSize,
    allowedExtensions: ['.pdf'],
  };
  public maxFileSizeMessage = `Размер файла должен быть не более ${Math.round(
    environment.portfolio.maxFileSize / 1024 / 1024
  )} МБ`;
  public fileUploaded = false;
  public fileUploadedList: {id: string; name: string}[] = [];
  public fileUploads?: Array<File>;

  //#endregion

  public achievements: {
    directionActivityId: string;
    data: AchievementList[];
  }[] = [];
  public directionActivities: DictDirectionActivity[] = [];
  public directionActivitiesByFilter: DictDirectionActivity[] = [];

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(),
    name: new UntypedFormControl(),
    directionActivityId: new UntypedFormControl(),
  });

  // StudPerson
  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "-",
    studPersonId: "",
    studPerson: this.studPerson
  }

  //#endregion

  constructor(
    private studentService: LKStudentService,
    private portfolioService: PortfolioService,
    private dictService: DictService,
    private activateRoute: ActivatedRoute,
    private notificationService: NotificationsService,
    private dialogService: DialogService,
  ) { }

  //#region Lifecycle methods

  async ngOnInit() {
    await this.getCurrentStudent();
  }

  public async getCurrentStudent() {
    const changeStudent = Number(localStorage.getItem('changeStudent'));
    this.studentService.getCurrentStudent()
      .subscribe(
        async response => {
          this.students = response;
          if(changeStudent) {
            this.studentModel = this.students[changeStudent-1];
          }
          else {
            this.studentModel = this.students[0];
          }
          this.getDirectionActivities();
          this.getAchievements();
        }
      );
  }

  public getAchievements() {
    this.loading = true;
    this.portfolioService.getSimpleAchievements({studentId: this.studentModel.externalId}).subscribe({
      next: (response) => {
        this.achievements = [];

        if (response == null || response.length == 0) return;

        for (let i = 0; i < response.length; i++) {
          let achievement = this.achievements.find(
            (_) => _.directionActivityId == response[i].directionActivityId
          );

          if (achievement == null) {
            achievement = {
              directionActivityId: response[i].directionActivityId,
              data: [],
            };
            this.achievements.push(achievement);
          }

          achievement.data = response[i].achievementList;
        }
      },
      error: (err) => {
        this.notificationService.showError(getErrorMessage(err));
      },
      complete: () => (this.loading = false),
    });
  }

  public getDirectionActivities() {
    this.dictService.getDirectionActivities().subscribe((response) => {
        this.directionActivities = response;
        this.getDirectionActivitiesByFilter();
      }
    );
  }

  public getDirectionActivitiesByFilter() {
    this.directionActivitiesByFilter = this.directionActivities.filter((x: DictDirectionActivity) => x.filialId == this.studentModel.filialId);
  }

  //#endregion

  //#region AchievementListView

  //#region Display

  public displayDirectionActivityName(id: string): string {
    const directionActivity = this.directionActivities.find((_) => _.id == id);

    return directionActivity?.name ?? '';
  }

  //#endregion

  //#region Handlers

  public removeAchievement({dataItem}: RemoveEvent): void {

    const dialog: DialogRef = DialogHelper.openRemoveDialog(this.dialogService, `${dataItem.name}`);

    dialog.result.subscribe((result) => {
      if (!(result instanceof DialogCloseResult) && result.text == 'Да') {
        this.portfolioService.deleteAchievement(dataItem.id).subscribe({
          next: () => {
            this.notificationService.showSuccess('Успешно');
            this.getAchievements();
          },
          error: (err) => this.notificationService.showError(getErrorMessage(err)),
        });
      }
    });
  }

  public editAchievement({dataItem}: EditEvent): void {

    this.editMode = true;
    this.resetForm();

    this.editForm = new FormGroup({
      id: new FormControl(dataItem.id),
      name: new FormControl(dataItem.name),
      directionActivityId: new FormControl(dataItem.directionActivityId),
    });

    this.fileUploadedList = dataItem.files;
    this.fileUploaded = dataItem.files?.length > 0;
  }

  //#endregion

  //#endregion

  //#region Download

  public downloadFile(file: {id: string; name: string}) {
    this.portfolioService.downloadAchievementFile(file.id).subscribe({
      next: (response) => {
        const blob = new Blob([response], {
          type: `application/pdf; charset=utf-8`,
        });

        saveAs(blob, file.name);
      },
      error: (err) =>
        this.notificationService.showError(err.status == 404 ? 'Файл не найден' : getErrorMessage(err)),
    });
  }

  //#endregion

  //#region EditForm

  public addAchievement() {

    this.editMode = true;
    this.resetForm();
  }

  public closeEditForm() {
    this.editMode = false;
    this.resetForm();
  }

  public saveAchievement() {

    const formData = new FormData();

    if (this.editForm.value.id) formData.append(`Id`, this.editForm.value.id);
    formData.append(`Name`, this.editForm.value.name);
    formData.append(`StudentId`, this.studentModel.externalId);
    formData.append(`DirectionActivityId`, this.editForm.value.directionActivityId);
    formData.append(`InReturnedStatus`, this.achievementStatusReturned.toString());

    this.fileUploads?.forEach((_) => {
      formData.append(`Files`, _, _.name);
    });

    this.editForm.value.id
      ? this.portfolioService.putAchievement(formData).subscribe({
          next: () => {
            this.getAchievements();
            this.closeEditForm();
            this.notificationService.showSuccess('Обновлено');
          },
          error: () => this.notificationService.showError('Не удалось обновить достижение'),
        })
      : this.portfolioService.postAchievement(formData).subscribe({
          next: () => {
            this.getAchievements();
            this.closeEditForm();
            this.notificationService.showSuccess('Сохранено');
          },
          error: () => this.notificationService.showError('Не удалось сохранить изменения'),
        });

    //this.editMode = false;
    //this.resetForm();
  }

  public resetForm() {
    this.fileUploaded = false;
    this.fileUploadedList = [];
    this.fileUploads = [];
    this.editForm.reset();
  }

  //#region File

  public fileSelected() {
    this.fileUploaded = true;
  }

  public fileRemoved() {
    this.fileUploaded = false;
  }

  //#endregion
}
