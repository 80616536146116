import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {filter, map, Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {DrawerItemExpandedFn, DrawerSelectEvent} from '@progress/kendo-angular-layout';
import {BreadCrumbItem} from '@progress/kendo-angular-navigation';
import {breadcrumbItemsMap} from "./models/home/breadcrumb-items.model";
import {Title} from '@angular/platform-browser';
import {menuItems} from './models/menuItems.model';
import {LKStudentService} from './services/LKStudent/lkstudent.service';
import {Renderer2} from '@angular/core';
import {LKStudent} from './models/profile/lkstudent.model';
import {LKStudPerson} from './models/profile/lkStudPerson.model';
import {TokenStorageService} from "./services/token.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {AuthService} from "./services/auth.service";
import {environment} from "../environments/environment";
import {tokenStore} from 'src/options/settings';
import {AlertStatisticsService} from './services/announcement/alert-statistics.service';
import {AlertStatistics} from './models/announcement/alert-statistics.model';
import {ShowService} from './services/announcement/show.service';
import {LKStudEduGroupService} from "./services/LKStudent/lkstud-edu-group.service";
import {UserSignatureService} from "./services/oferta/user-signature.service";
import {CreateQuery} from "./helpers/createQuery-helper";
import {SignatureTypeEnum} from "./models/oferta/enums/dictSignatureType.enum";

const is = (fileName: string, ext: string) =>
  new RegExp(`.${ext}\$`).test(fileName);


interface Item {
  text?: string;
  icon?: string;
  path?: string;
  selected?: boolean,
  separator?: boolean,
  id?: string,
  parentId?: string,
  url?: string
  disabled?: boolean,
  return?: boolean
}
const menuExpanded: string  = "menuExpanded"
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Личный кабинет студента';
  public fullName? = ""
  public isAdmin = false;
  public isSwitchActive = false;
  public fullTitle = environment.headerTitle.full;
  public shortTitle = environment.headerTitle.short;
  public currentId = 0;
  public userHasSimpleSignature: boolean = true;

  public photoUrl = ''
  public selected = "Inbox";
  public expanded: boolean =  (localStorage.getItem(menuExpanded) ?? "true") == "true";
  private routesData: Subscription = new Subscription;
  private services: Array<Item> = [
    {text: "Новости и объявления", icon:"k-i-parameter-date-time", url:"/alert", path: "/alert", id: "announcement"},
    {text: "Портфолио", icon: "k-i-inherited", url: "/portfolio", path:"/portfolio", id: "portfolio"},
    {text: "Образование", icon: "k-i-chart-rose", url: "/education", path: "/education", id: "education"},
    {text: "Успеваемость", icon:"k-i-graph", url: "/studyProgress", path: "/studyProgress", id: "studyProgress"},
    {text: "Электронные образовательные ресурсы и ЭБС", icon:"k-i-dictionary-add", url: "/electroresources", path: "/electroresources", id: "electroresources"},
    {text: "Трудовая деятельность", icon:"icon-briefcase", url: "/employment", path: "/employment", id: "employment"},
    //{text: "Расписание занятий", icon: "k-i-calendar-date", path: "/WIP", disabled: true, id: "wip"},
    //{text: "Расписание экзаменов", icon: "k-i-calendar", path: "/WIP", disabled: true, id: "wip"},
    //{text: "Стипендии", icon:"k-i-calculator", path: "/WIP", disabled: true, id: "wip"},
    //{text: "Социальные документы", icon:"k-i-files-error", disabled: true, path: "/WIP", id: "wip"}
  ];
  private externalLinks: Array<Item> = environment.menuItems.map((item: any) => ({...item, return: true}));
  public items: Array<Item> = [
    {icon: "k-i-menu"},
    {text: "Профиль", icon: "k-i-user", url:"/home", selected: true, path: "/home", id: "home"},
  ];

  public menuItems: any[] = menuItems;
  public currentStudents: any[] = [];

  public iconClass({ text, items }: any): any {
    return {
      "k-i-file-pdf": is(text, "pdf"),
      "k-i-folder": items !== undefined,
      "k-i-html": is(text, "html"),
      "k-i-image": is(text, "jpg|png"),
      "k-icon": true,
    };
  }

  public updateStudentName() {
    const test = this.menuItems.find(item => item.text);
    menuItems[0].text;
  }

  public studPersons: LKStudPerson[] = [];
  public studPerson: LKStudPerson = {
    firstName: '',
    lastName: '',
    middleName: '',
    isMale: false,
    birthday: new Date()
  }

  // Student
  public students: LKStudent[] = [];
  public studentModel: LKStudent = {
    externalId: "",
    studentNumber: "",
    studPersonId: "",
    studPerson: this.studPerson
  }

  public getStudEduGroup(studentId: string, id: number) {
    this.studEduGroupService.getStudEduGroup(studentId)
        .subscribe(
            response => {
              this.currentStudents.push({
                text: `Студент ${response.eduGroup?.groupName}`,
                studentId: response.studentId,
                id: id + 1
              })
              this.currentStudents = this.currentStudents.sort((a:any, b:any) => a.id - b.id);
            //  console.log(this.currentStudents);
            }
        );
  }

  //Getting student by login
  public getCurrentStudent() {
    const changeStudent = localStorage.getItem('changeStudent');
    this.studentService.getCurrentStudent( )
      .subscribe(
        response => {
          this.currentId = 1;
          if(changeStudent) {
            this.currentId = Number(changeStudent);
          }
          menuItems[0].text = response[0].studPerson.lastName + " " + response[0].studPerson.firstName + " " + response[0].studPerson.middleName;
          this.studentModel.studPerson.firstName = response[0].studPerson.firstName + " " + response[0].studPerson.lastName + " " + response[0].studPerson.middleName;
          this.fullName = response[0].studPerson.lastName + " " + response[0].studPerson.firstName + " " + response[0].studPerson.middleName;
          this.photoUrl = `url('${environment.apiEndpoint}studperson/GetStudPersonPhoto/${response[0].studPersonId}')`;

          // Add switch method
          for(let i = 0; i < response.length; i++) {
            this.getStudEduGroup(response[i].externalId, i);
          }
          //console.log(response);
        }
      );
  }

  public onSelect(ev: DrawerSelectEvent): void {
    //console.log(this.expanded);
    localStorage.setItem(menuExpanded, this.expanded ? "false" : "true")
    if (ev.item.icon == "k-i-menu") {
      ev.preventDefault();
      this.expanded = !this.expanded;
    }
    else if (ev.item.path != undefined)
      this.router.navigate([ev.item.path]);

  }


  public breadcrumbitems: BreadCrumbItem[] = [
    {
      text: "Главная",
      title: "home",

    },
    {
      text: "Моя страница",
      title: "studentPersonalPage",
    }
  ];

  public expandedIndices = [2];
  public isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  constructor(private router: Router,
              private titleService: Title,
              private studentService: LKStudentService,
              private el: ElementRef,
              private studEduGroupService: LKStudEduGroupService,
              private alertStatisticsServise: AlertStatisticsService,
              private showService: ShowService,
              private renderer: Renderer2,
              private jwtHelper: JwtHelperService,
              private tokenStore: TokenStorageService,
              public authService : AuthService,
              private cdRef : ChangeDetectorRef,
              private userSignatureService: UserSignatureService
  ) {
    this.items.splice(2, 0, ...this.externalLinks);
    this.items.splice(2, 0, ...this.services.filter(item => environment.services.includes(item.id)));
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const splitURL = e.url.split('/', 3);
        const prevSelected = this.items.find((i) => i.selected);
        if (prevSelected) {
          prevSelected.selected = false;
        }
        const newSelected = this.items.find((i) =>
            i.path === `/${splitURL[1]}` ||  i.path === `/${splitURL[1]}/${splitURL[2]}`);
        if (newSelected) {
          newSelected.selected = true;
        } else {
          this.items[0].selected = true;
        }
        this.items = [...this.items];
      }
    });
    this.initRoutes();
  }

  public calculatedParametr: AlertStatistics["calculatedParametr"] = [] ;
  public getAlertStatistics() {
    this.alertStatisticsServise.getAlertStatistics()
    .subscribe(
        response => {
            this.calculatedParametr = response["calculatedParametr"];
        }
    );
}

  ngOnInit(): void {
    if (this.authService.isUserAuthenticated()) {
      this.getCurrentStudent();
      this.studPersonHasSignature();
    }

    let role = [] as string[];
    const token = this.tokenStore.getAuthToken();
    if(token !== null)
    {
      const user = this.jwtHelper.decodeToken(token);
      if (user.role)
        role = user.role;
    }

    const switchUser = localStorage.getItem('switchStudent');
    if(switchUser === 'true')
      this.isSwitchActive = true;

    if(role.includes('admin'))
      this.isAdmin = true;

    this.cdRef.detectChanges();

    if (this.items.find(item => item.id === 'announcement')) {
      this.getAlertStatistics();
      this.showService.subscriber$.subscribe(() => {
        this.getAlertStatistics();
      });
    }

    // Add page titles to components
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        if (route.snapshot.data['title']) {
          routeTitle = route!.snapshot.data['title'];
        }
        return routeTitle;
      })
    )
    .subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`${title} – личный кабинет студента`);
      }
    });

  }

  public studPersonHasSignature() {

    if (localStorage.getItem('switchStudent') === 'true')
      return;

    const query = CreateQuery({
      userSignatureTypeEnum: SignatureTypeEnum.Simple
    });

    this.userSignatureService.studPersonHasSignature(query)
      .subscribe(response => {
        this.userHasSimpleSignature = response;
      })
  }

    public baseSportsIconUrl =
    "https://demos.telerik.com/kendo-ui/content/shared/icons/sports/";
  public baseIconUrl =
    "https://demos.telerik.com/kendo-ui/content/shared/icons/16/";

  public sportsIconUrl(imageName: string): string {
    return this.baseSportsIconUrl + imageName + ".png";
  }

  public iconUrl(imageName: string): string {
    return this.baseIconUrl + imageName + ".png";
  }

  public ngOnDestroy(): void {
    this.routesData.unsubscribe();
  }

  public menuClick(value: any) {
    switch (value.return) {
      case true:
        return true;
      case false:
        return false;
      default:
        return false;
    }
  }

  switchUser() {
    this.router.navigate(['/switchuser']);
  }

  public onItemClick(item: BreadCrumbItem): void {
    const selectedItemIndex = this.breadcrumbitems.findIndex((i) => i.title === item.title);
    const url = this.breadcrumbitems
      .slice(0, selectedItemIndex + 1)
      .map((i) => `${i.title?.toLowerCase()}` );
    this.router.navigate(url);
  }

  private initRoutes(): void {
    this.routesData = this.router.events.subscribe(() => {
      // Exclude query parameters from URL
      const route = this.router.url;
      this.breadcrumbitems = route
        .substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length)
        .split('/')
        .filter((segment)=> breadcrumbItemsMap.has(segment))
        .map((segment) => {
          return {
            text: breadcrumbItemsMap.get(segment),
            title: segment
          };
        });

      this.breadcrumbitems = [
        // {
        //   text: 'Главная',
        //   title: ''
        // },
        ...this.breadcrumbitems
      ];
    });
  }


  showLoginPage(){
    let route = window.location.pathname//this.router.url
    return !this.authService.isUserAuthenticated() && route != "/callback" // route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) != "/callback"
  }

  showCallbackPage(){
    let route = window.location.pathname//this.router.url
    return !this.authService.isUserAuthenticated() && route == "/callback"// route.substring(0, route.indexOf('?') !== -1 ? route.indexOf('?') : route.length) == "/callback"
  }

  public changeStudent(studentId: any) {
    localStorage.setItem('changeStudent', studentId);
    window.location.reload();
  }

  public activeItem(id: any) {
    return this.currentId === id;
  }

  stopUserPreview() {
    let currentToken = localStorage.getItem('currentToken');
    localStorage.setItem('switchStudent', 'false');
    if(currentToken !== null)
    {
      localStorage.setItem(tokenStore, currentToken);
      localStorage.removeItem('currentToken');
      window.location.reload();
    }
  }

  public logOut = () => {
    window.location.reload();
    localStorage.removeItem('switchStudent');
    localStorage.removeItem('changeStudent');
    this.tokenStore.deleteToken()
  }

  public activeClass!: boolean;

  public onMenuClick() {
    this.activeClass = !this.activeClass;
  }

}
